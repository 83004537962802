import React, { useRef, useEffect } from "react"
import { navigate } from "gatsby"

import "../theme/app.scss"
import "./index.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "react-circular-progressbar/dist/styles.css"

import Header from "../components/header"
import Link from "gatsby-link"
import Footer from "../components/footer"
import SEO from "../components/seo"
import Flourish from "../images/home/flourish.mp4"
import FlourishMob from "../images/home/flourish-mob.mp4"

import GotQuestions from "../components/got-questions/got-questions"

import { TrustCompanies } from "../components/trust-companies/trust-companies"
import { FlourishingEasy } from "../components/flourishing-easy/flourishing-easy"
import { Pioneers } from "../components/pioneers/pioneers"
import FlourishingCta from "../components/flourishing-cta/flourishing-cta"
import { Impact } from "../components/impact/impact"
import Layout from "../components/layout"

const IndexPage = () => {
  const demoVideo = useRef(null)

  useEffect(() => {
    if (!demoVideo.current) return
    demoVideo.current.play()
  }, [])

  useEffect(() => {
    let snapContainerHome = document.getElementById("snap-container")
    if (snapContainerHome) snapContainerHome.focus()
  })

  return (
    <>
      <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <div tabIndex="0" className="home-container" id="snap-container">
          <div className="section-videoWrap">
            <video
              autoPlay
              loop
              muted
              playsInline
              loading="lazy"
              className="desktopView"
            >
              <source src={Flourish} type="video/mp4" />
              Sorry, your browser doesn't support videos.
            </video>
            <video
              autoPlay
              loop
              muted
              playsInline
              loading="lazy"
              className={"mobileView"}
            >
              <source src={FlourishMob} type="video/mp4" />
              Sorry, your browser doesn't support videos.
            </video>
            <section className="section-header">
              <div
                className="announcementHome"
                onClick={() => navigate("/press")}
              >
                <span>Press</span>
                <p>SHAPE launches ProScore</p>
              </div>
              <h3 className="home-title-text" id="home-1">
                Employees Flourish Up To 10x Faster with SHAPE
              </h3>

              <p className="home-desc">
                Guide employees to happiness and high-performance through a
                flourishing mindset.
              </p>
              <div className="home-btn-flex">
                <Link className="home-btn-primary" to="/launch">
                  Get SHAPE
                </Link>
                <Link to="/science" className="home-btn-secondary">
                  See the Science
                </Link>
              </div>
            </section>
          </div>

          <TrustCompanies />
          <Impact />
          <FlourishingEasy />
          <FlourishingCta page={"home"} />
          <Pioneers />
          <GotQuestions />
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
